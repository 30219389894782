import React, { useEffect } from 'react'
import { Layout, Typography } from 'antd'
import Header from './Header'
import Foot from './Foot'
import SubHeader from './SubHeader'
import { s } from './style'
import Portfolio from '../Portfolio/Portfolio'
import Faq from '../Faq'
import { useMatomo } from '@datapunt/matomo-tracker-react'

const { Content } = Layout
const { Title, Paragraph } = Typography

const Branding = () => {
  // Matomo tracking
  const { trackPageView } = useMatomo()

  // Track page view
  useEffect(() => {
    trackPageView({
      documentTitle: 'Monitor CE', // optional
      // href: 'https://LINK.TO.PAGE', // optional
      // customDimensions: [
      //   {
      //     id: 1,
      //     value: 'loggedIn',
      //   },
      // ], // optional
    })
    // eslint-disable-next-line
  }, [])

  const Intro = () => (
    <section style={{ margin: 30, width: '100%' }}>
      <Title level={2}>Monitor je circulaire economie</Title>
      <Title level={4}>MONITORING | INZICHTEN | HANDELINGSPERSPECTIEVEN</Title>
      <Paragraph style={s.about}>
        Ontdek, onderzoek, en monitor de materiaalstromen in jouw gebied. Ontvang inzichten over materiaal- en afvalstatistieken,
        doelstellingen voor de circulaire economie, meet je voortgang en neem actie met de aangeboden handelingsperspectieven.
      </Paragraph>
      <Title level={3} style={s.marg}>
        Hoe het werkt
      </Title>
      <iframe
        width={'100%'}
        height={600}
        src={'https://www.youtube.com/embed/jnXS9abpXiM?rel=0&loop=1'}
        title={'YouTube video player'}
        frameBorder={0}
        allow={
          'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        }
        allowFullScreen
      />
      {/* <iframe // map
        style={{ marginBottom: 30 }}
        allowFullScreen
        frameBorder={0}
        width={'100%'}
        height={600}
        loading={'eager'}
        src={
          'https://monitor-gate-map.s3.eu-central-1.amazonaws.com/overview_activities_flowmap.html'
        }
        title={'Afvalproductie per economische sector'}
      /> */}

      <Title level={3} style={{ marginTop: 64, marginBottom: 10 }}>Wij werken samen met </Title>
    </section>
  )

  return (
    <Layout style={s.brand}>
      <Header />
      <SubHeader />
      <div style={{ margin: 30 }}>
        <div style={s.wrapper}>
          <Content style={s.layout}>
            <Intro />
            <Portfolio />
            <Faq />
          </Content>
        </div>
      </div>
      <Foot />
    </Layout>
  )
}

export default Branding
