import React from 'react'
import './App.less'

import { Branding, ErrorBoundary } from './comps'

const s = {
  app: { width: '100vw' as any, boxSizing: 'border-box' as any, backgroundColor: 'white' as any }
}

const App = () => (
  <section style={s.app}>
    <ErrorBoundary>
      <Branding />
    </ErrorBoundary>
  </section>
)

export default App
