// import * as Sentry from '@sentry/browser'
// import React from 'react'^
import React, { Component, ReactNode, ErrorInfo } from 'react'
import ErrorUI from '../ui/ErrorUI'

interface ErrorBoundaryState {
  hasError?: boolean
  eventId?: string
}

class ErrorBoundary extends Component<ErrorBoundaryState> {
  public static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true }
  }

  public state: ErrorBoundaryState = {
    hasError: false
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Sentry.withScope((scope) => {
    //   scope.setExtras((errorInfo as unknown) as Record<string, unknown>)
    //   const eventId = Sentry.captureException(error)
    //   this.setState({ eventId })
    // })
  }

  public render(): ReactNode {
    const { hasError } = this.state

    if (hasError) {
      return <ErrorUI />
    }

    return this.props.children
  }
}

export default ErrorBoundary
