import React from 'react'
import { Layout } from 'antd'
import { s } from './style'

const { Footer } = Layout

const Foot = () => {
  return (
    <Footer style={s.footer}>
      <a href='https://www.geofluxus.com' target='_blank' rel='noopener noreferrer'>
        <img src={'./img/Logo_V_White.png'} alt='geoFluxus' style={s.logo} />
      </a>
      <div style={s.div}>
        <h2 style={s.h2}>Contact</h2>
        <p style={s.p}>
          Contacteer ons voor suggesties en vragen op{' '}
          <a style={s.a} href='mailto:info@monitorce.nl'>
            info@monitorce.nl
          </a>
        </p>
      </div>
    </Footer>
  )
}
export default Foot
