import React, { FC } from 'react'
import { Card, Result, Layout } from 'antd'
import Meta from './Meta'

const { Content } = Layout
const s = {
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'
  }
}
const ErrorUI: FC = () => {
  return (
    <Content style={s.content}>
      <Meta title={'Error - something went wrong'} />
      <Card>
        <Result
          status='error'
          title={'Something went wrong.'}
          subTitle={'Please try again later.'}
        />
      </Card>
    </Content>
  )
}

export default ErrorUI
