import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Breadcrumb, Button } from 'antd'
import { s } from './style'
import { useMatomo } from "@datapunt/matomo-tracker-react"  // <-- TRACKING

const SubHeader = () => {  
  const { trackEvent } = useMatomo()  // <-- TRACKING
  const isSmallScreen = useMediaQuery({ maxWidth: 1200 })
  const responsiveFont = isSmallScreen ? 12 : 16

  const SIDER_LINKS = [
    { h: 'OVER ONS', a: 'https://www.geofluxus.com/' },
    {
      h: 'CONTACTEER ONS',
      a: 'mailto:info@monitorce.nl?Subject=Monitor%20CE%20bestemmingspagina%20contact'
    }
  ]
  return (
    <div style={{ ...s.subheader }}>
      <Breadcrumb style={{}} separator='|'>
        {SIDER_LINKS.map((link, i) => (
          <Breadcrumb.Item key={i}>
            <Button
             key={i} 
             type='link' 
             style={{ ...s.items, fontSize: responsiveFont }}
             onClick={ () => trackEvent({ category: 'Link', action: 'click-Link', name:link.h }) }>
              <a href={link.a} target='_blank' rel='noopener noreferrer' style={{ color: 'white' }}>
                {link.h}
              </a>
            </Button>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  )
}

export default SubHeader
