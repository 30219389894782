export const s = {
  card: {
    width: 330,
    height: 290,
    margin: 20,
    display: 'flex' as any,
    flexDirection: 'column' as any,
    alignItems: 'center' as any,
    justifyContent: 'center' as any,
    textAlign: 'center' as any,
    background: 'white' as any
  },
  marg: { margin: '48px 0px 24px 0px' },
  backdrop: {
    padding: 24,
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 1)), url('./img/img.png')`,
    backgroundSize: 'cover'
  },
  gallery: {
    zIndex: 101,
    display: 'flex',
    width: '100%',
    justifyContent: 'center' as any,
    alignItems: 'center' as any,
    margin: '38px 0px'
  },
  wrap: {
    maxWidth: 1300,
    display: 'flex' as any,
    justifyContent: 'center' as any,
    alignItems: 'center' as any,
    flexDirection: 'row' as any,
    flexWrap: 'wrap' as any
  }
}
