import React from 'react'
import { Typography, Collapse } from 'antd'

const { Title, Paragraph } = Typography
const { Panel } = Collapse

const s = {
  wrapper: {
//     display: 'flex' as any,
//     justifyContent: 'center' as any,
//     alignItems: 'center' as any,
    width: '100%' as any,
    margin: '30' as any
  }
}

const Faq = () => {
  const FAQ = [
    {
      q: 'Welke data gebruikt geoFluxus om deze monitor op te zetten voor gemeenten en provincies?',
      a: 'We maken hoofdzakelijk gebruik van bestaande databronnen die door overheden worden verzameld en beheerd. Wat de monitor uniek maakt is ons herclassificatiemodel dat al deze databronnen integreert om inzichten en handelingsperspectieven voor de circulaire transitie op lokaal en regionaal niveau te tonen.'
    },
    {
      q: 'Hoe kan ik toegang krijgen tot de Monitor Circulaire Economie voor mijn gemeente/provincie/regio en hoe vaak wordt de data geupdated?',
      a: 'Overheden kunnen toegang krijgen door contact met ons op te nemen. Wij verzorgen het gehele process van aanvraag tot verwerking van de data.  Wij ontwikkelen monitors op elk gewenst schaalniveau: gemeentelijk, provinciaal, en nationaal. De data wordt ten minste iedere 6 weken geupdated. Tegelijkertijd ontwikkelen we een bedrijfsspecifiek platform voor individuele bedrijven en bedrijventerreinen. Voor meer informatie ',
      link: true
    },
    {
      q: 'Kunnen bedrijven ook gebruik maken van deze inzichten?',
      a: `Absoluut! De monitor is zo open mogelijk maar zo gesloten als nodig zodat iedereen van deze inzichten gebruik kan maken in de transitie naar een circulaire economie.
      Het inzichtelijk maken van waar welke afvalstromen beschikbaar zijn en wat er vervolgens mee gebeurt toont waar circulaire (zakelijke) kansen zich bevinden. Welke materialen worden op dit moment nog te laagwaardig verwerkt? Welke industrieen zijn op zoek naar welke materialen en hoe kunnen deze verduurzaamd worden? Het platform laat bedrijven toe om te anticiperen op materialenschaarste en hogere verwerkings- en transportkosten om circulaire strategieen te ontwikkelen. Tegelijkertijd ontwikkelen we een bedrijfsspecifiek platform waar bedrijven unieke inzichten krijgen voor hun situatie. Voor meer informatie `,
      link: true
    },
    {
      q: 'Kan de monitor aangepast worden voor specifieke behoeften voor mijn provincie/stad/regio?',
      a: 'Jazeker. De monitor is een levend project en kan worden aangepast aan de specifieke behoeften van de gebruiker binnen de kaders van beschikbare tijd en budget. Op deze manier worden constant aanvullende modules ontwikkeld die vervolgens voor iedere gebruiker toegankelijk worden. Zo garanderen we een constante verbetering van de monitor voor alle gebruikers.'
    }
  ]

  return (
    <div style={{ ...s.wrapper, flexDirection: 'column', margin: '54px 0px' }}>
      <Title level={3}>Veel gestelde vragen</Title>
      <br />
      <Collapse
        ghost
        defaultActiveKey={['1']}
        style={{ maxWidth: '712px', margin: '0px 0px 64px 0px' }}>
        {FAQ.map((faq, i) => (
          <Panel header={<Title level={5}>{faq.q}</Title>} key={i + 1}>
            <Paragraph>
              {faq.a}
              {faq?.link && (
                <a style={{ textDecoration: 'underline' }} href='mailto:info@monitorce.nl'>
                  contacteer ons
                </a>
              )}
            </Paragraph>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}
export default Faq
