import React from 'react'
import { 
  Layout,
  Typography,
  // Button 
} from 'antd'
import { s } from './style'

const { Header: Head } = Layout

const Header = () => {
  // const LINKS = [
  // ]

  return (
    <Head style={s.header}>
      <Typography.Text strong style={s.title}>
        MONITOR CIRCULAIRE ECONOMIE
      </Typography.Text>
      <section>
        {/* {LINKS.map((link, i) => (
          <Button key={i} type='link'>
            <a href={link.a} target='_blank' rel='noopener noreferrer'>
              {link.h}
            </a>
          </Button>
        ))} */}
      </section>
    </Head>
  )
}

export default Header
