import React from 'react'
import { Card, Typography, Button } from 'antd'
import { s } from './style'
import { useMatomo } from "@datapunt/matomo-tracker-react"  // <-- TRACKING

const { Title, Text } = Typography
const Portfolio = () => {
  const { trackEvent } = useMatomo()  // <-- TRACKING
  const PORTFOLIO = [
    {
      title: 'Utrecht',
      sub: 'Provincie',
      url: 'https://www.monitorce.nl/provincieutrecht/'
    },
    {
      title: 'Amsterdam',
      sub: 'Gemeente',
      url: 'https://onderzoek.amsterdam.nl/interactief/monitor-circulaire-economie'
    },
    {
      title: 'Zaanstad',
      sub: 'Gemeente',
      url: 'https://www.monitorce.nl/zaanstad/2022'
    },
    {
      title: 'ICER',
      sub: 'Open Source code',
      url: 'https://github.com/geoFluxus/ICER',
      btn: 'BEKIJK CODE'
    },
    {
      title: 'ICER 2023',
      sub: 'Rapportage',
      url: 'https://www.pbl.nl/nieuws/2023/icer-2023-circulaire-doelen-liggen-nog-ver-buiten-bereik',
      btn: 'BEKIJK RAPPORTAGE'
    },
    {
      title: 'Flevoland',
      sub: 'Provincie',
      // url: ''
    },
    { title: 'Rijkswaterstaat', sub: 'Overheid' },
    { title: 'Antwerpen', sub: 'Stad' }
  ]

  return (
    <>
      <div style={s.backdrop}>
        <section style={s.gallery}>
          <section style={s.wrap}>
            {PORTFOLIO.map((card, i) => (
              <Card bordered key={i} style={{ ...s.card, opacity: card?.url ? 1 : 0.65 }}>
                <Title>{card.title}</Title>
                <Text>{card.sub}</Text>
                <br />
                <Button
                  type={'primary'}
                  style={{ marginTop: 24 }}
                  size='large'
                  disabled={!card?.url}
                  onClick={ () => trackEvent({ category: 'Portfolio', action: 'click-button', name:card.title }) }>
                  <a href={card.url} target='_blank' rel='noopener noreferrer'>
                    {!card.url ? 
                      'Binnenkort' :
                      card.btn ? card.btn : 'BEZOEK MONITOR'
                    }
                  </a>
                </Button>
              </Card>
            ))}
          </section>
        </section>
      </div>
    </>
  )
}

export default Portfolio
