export const s = {
  layout: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column' as any,
    boxSizing: 'border-box' as any,
    width: '100vw',
    alignItems: 'center'
  },
  header: {
    display: 'flex' as any,
    flexDirection: 'row' as any,
    alignItems: 'center' as any,
    justifyContent: 'space-between' as any,
    width: '100%',
    minHeight: '95px' as any,
    background:
      'radial-gradient( circle at 0% 20%, #3886D3 18.3%, rgba(86, 151, 216, 0.35) 32.88%, rgba(229, 235, 241, 0.0325902) 45.31%, rgba(244, 244, 244, 0) 100%)' as any
  },
  title: {
    color: '#F4F4F4' as any,
    fontSize: '24px' as any,
    lineHeight: '32px' as any,
    textTransform: 'uppercase' as any,
    letterSpacing: '0.2px' as any,
    paddingLeft: '14px' as any
  },
  subheader: {
    display: 'flex' as any,
    flexDirection: 'row' as any,
    alignItems: 'center' as any,
    justifyContent: 'space-between' as any,
    backgroundColor: '#070D22' as any,
    height: 54,
    paddingLeft: 50,
    paddingRight: 50,
    marginBottom: 40,
    position: 'sticky' as any,
    top: 0,
    zIndex: 9999
  },
  subContainer: {
    marginLeft: 50,
    marginRight: 50,
    padding: 0
  },
  items: {
    color: 'white' as any,
    textTransform: 'uppercase' as any,
    letterSpacing: '0.08em' as any
  },
  footer: {
    display: 'flex' as any,
    flexDirection: 'row' as any,
    alignItems: 'flex-start' as any,
    justifyContent: 'flex-start' as any,
    with: '100vw' as any,
    height: 300,
    gap: '2rem',
    backgroundColor: '#070D22' as any,
    padding: '100px 50px' as any,
    color: 'white' as any
  },
  logo: {
    maxHeight: '150px' as any,
    objectFit: 'contain' as any
  },
  div: {
    maxWidth: '263px' as any
  },
  h2: {
    color: 'white' as any,
    fontWeight: '600' as any,
    marginBottom: '16px' as any
  },
  p: {
    color: 'white' as any,
    fontStyle: 'normal' as any,
    fontWeight: 'normal' as any,
    fontSize: '16px' as any,
    lineHeight: '24px' as any
  },
  a: {
    color: 'white' as any,
    textDecoration: 'none' as any
  },

  // -- wrapper styling to center content on the page --
  wrapper: {
    display: 'flex' as any,
    justifyContent: 'center' as any,
    alignItems: 'center' as any,
    minWidth: '900px' as any,
    maxWidth: '1300px',
    margin: 'auto' as any
  },
  marg: { margin: '64px 0px 24px 0px' },
  brand: { width: '100vw' as any, display: 'flex' as any, flexDirection: 'column' as any },
  about: { maxWidth: 700, margin: '20px 0px' }
}
